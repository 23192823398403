import { AutoneTranslation, i18nAutone } from '@autone/translations';
import {
  AppShell,
  ErrorFallback,
  useAppSettings,
  useGetUserConfigQuery,
} from '@autone/ui';
import { BUYING_ADMIN, BUYING_READONLY, BUYING_STANDARD } from '@autone/utils';
import { ErrorBoundary } from 'react-error-boundary';

import { APP_NAME } from './constants';
import { useFirstTimeUserCheck, useSetLastActive } from './hooks';
import { store } from './redux/store';
import {
  BatchRoutes,
  BuyConfirmedRoutes,
  CatalogueLoadingRoutes,
  ErrorRoutes,
  NewBuyRoutes,
  OverviewRoutes,
  QuantificationLoadingRoutes,
  SubmittedRoutes,
  WelcomeRoutes,
} from './routes';

function App() {
  const routes = [
    OverviewRoutes,
    WelcomeRoutes,
    ErrorRoutes,
    NewBuyRoutes,
    SubmittedRoutes,
    CatalogueLoadingRoutes,
    QuantificationLoadingRoutes,
    BuyConfirmedRoutes,
    BatchRoutes,
  ];

  // set up app settings - intercom, sentry, mixpanel & fullview
  useAppSettings('buying');

  // get the user config - this gets data from core/user/me and populates redux store
  useGetUserConfigQuery();

  // hook contains logic to check if a user is visiting buying for the first time, and handling the redirect to the welcome page if true
  useFirstTimeUserCheck();

  // set last active for the buying module, separate from last active in core/user/me endpoint
  useSetLastActive();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AutoneTranslation namespace={APP_NAME} i18n={i18nAutone}>
        <AppShell
          reduxStore={store}
          routes={routes}
          permissionGroups={[BUYING_ADMIN, BUYING_STANDARD, BUYING_READONLY]}
        />
      </AutoneTranslation>
    </ErrorBoundary>
  );
}

export default App;
