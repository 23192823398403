import { useAutoneTranslation } from '@autone/translations';
import {
  type CustomTheme,
  DashboardSidebar,
  LanguageSelectionPopover,
  SideBarContent,
  useGetPagePadding,
  withLanguagePreferenceFeatureFlag,
} from '@autone/ui';
import { Stack, type Theme, useTheme } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useState } from 'react';

import { APP_NAME } from '../constants';
import { StepHeader } from '../features/step-header/components';
import { StepProgress } from '../features/step-progress/components';

import MenuIconButton from './navbar/MenuIconButton';

type LayoutTypes = {
  children: React.ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  showStepper?: boolean;
};

// ----------------------------------------------------------------------

const STEPPER_HEADER_HEIGHT = 190;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
});

const MainStyle = styled('div')(
  ({
    theme,
    getPagePadding,
  }: {
    theme: CustomTheme;
    getPagePadding: () => number;
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'scroll',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: getPagePadding(),
    paddingRight: getPagePadding(),
  }),
);

const StepperWrapperStyle = styled('div')(
  ({
    theme,
    getPagePadding,
  }: {
    theme: CustomTheme;
    getPagePadding: () => number;
  }) => ({
    color: theme.palette.grey[800],
    backgroundColor: 'white',
    height: STEPPER_HEADER_HEIGHT,
    paddingLeft: getPagePadding(),
    paddingRight: getPagePadding(),
    paddingTop: theme.spacing(3),
    boxShadow: theme.customShadows.z8,
    alignItems: 'center',
  }),
);

const LanguageSelectionRenderer = withLanguagePreferenceFeatureFlag(
  LanguageSelectionPopover,
  'buying',
);

export const PageLayout = ({ children, showStepper }: LayoutTypes) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme<Theme & CustomTheme>();

  const { common } = useAutoneTranslation<typeof APP_NAME>();

  const { getPagePadding } = useGetPagePadding();

  return (
    <RootStyle>
      <Stack>
        <MenuIconButton setOpen={setOpen} />
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        >
          <SideBarContent
            renderItems={(isCollapse) => (
              <LanguageSelectionRenderer
                isCollapse={isCollapse}
                labelDict={{
                  'en-US': common('language.label.english-us'),
                  en: common('language.label.english'),
                  it: common('language.label.italian'),
                  fr: common('language.label.french'),
                }}
                errorText={common('language.error-text')}
              />
            )}
          />
        </DashboardSidebar>
      </Stack>
      {showStepper ? (
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'scroll',
          }}
        >
          <StepperWrapperStyle theme={theme} getPagePadding={getPagePadding}>
            <Stack spacing={3}>
              <StepHeader />
              <StepProgress />
            </Stack>
          </StepperWrapperStyle>
          <MainStyle theme={theme} getPagePadding={getPagePadding}>
            {children}
          </MainStyle>
        </Stack>
      ) : (
        <MainStyle theme={theme} getPagePadding={getPagePadding}>
          {children}
        </MainStyle>
      )}
    </RootStyle>
  );
};

export function PageLayoutWithStepper({ children }: LayoutTypes) {
  return <PageLayout showStepper>{children}</PageLayout>;
}
